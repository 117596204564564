import React, { useState, useRef } from 'react';
import { ChevronRight, Check, Info, ShieldCheck } from 'lucide-react'; 
import Button from '../components/ui/button';
import Input from '../components/ui/input';
import Select from '../components/ui/select';
import { Alert } from '../components/ui/alert';

const appUrl = process.env.REACT_APP_URL;

const CheckoutPage = () => {
  const plans = [
    { duration: '1 Month', price:9.99, save: '0%', guarantee: '7-day', lastSold: '80s' },
    { duration: '12 Months', price:49.99, save: '50%', guarantee: '30-day', lastSold: '72s', originalPrice: 139.98, totalLabel: 'total' },
    { duration: '3 Months', price:24.99, save: '23%', guarantee: '30-day', lastSold: '94s', originalPrice: 38.97, totalLabel: 'total' },
    { duration: '6 Months', price:34.99, save: '36%', guarantee: '30-day', lastSold: '57s', originalPrice: 77.94, totalLabel: 'total' },
  ];

  // Add the trial plan
  const trialPlan = { duration: '24 Hours', price: 1.99, save: '0%', guarantee: 'No money-back', lastSold: 'Just Now', totalLabel: 'Trial' };

  const [selectedPlan, setSelectedPlan] = useState(plans[1]);
  const [email, setEmail] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(''); 
  const [couponCode, setCouponCode] = useState('');

  const detailsSectionRef = useRef(null);

  // Plan selection and smooth scroll
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    detailsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      plan: selectedPlan.duration,
      email,
      deviceType,
      paymentMethod,
      amount: selectedPlan.price,
      domain: window.location.hostname,
      paymentStatus: 'Pending'
    };
  
    try {
      // Step 1: Send the order details to the backend for payment processing
      const response = await fetch(`/api/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
  
      if (result.success) {
        // Redirect to the returned payment URL (either PayPal or Stripe)
        window.location.href = result.url; // Use the `url` field returned from the backend
      } else {
        alert('Error saving your order');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      alert('An error occurred while saving your order');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <main className="container mx-auto px-6 py-8 pt-24">
        {/* Step 1: Choose Your Plan */}
        <section className="mb-12">
          <div className="flex items-center mb-6">
            <span className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white px-3 py-1 rounded-full text-sm mr-3">Step 1</span>
            <h2 className="text-4xl font-extrabold text-[#ff6b6b]">Choose Your Plan</h2>
          </div>
          <Alert className="mb-6">
            <span className="font-semibold">50% Off Today</span> - Limited time offer!
          </Alert>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`relative rounded-lg p-6 cursor-pointer border-2 transition-all duration-300 ${
                  selectedPlan?.duration === plan.duration 
                    ? 'bg-[#ffe7e7] border-[#ff6b6b] shadow-lg scale-105' 
                    : 'bg-white border-transparent hover:border-[#ff6b6b] hover:shadow-md'
                }`}
                onClick={() => handlePlanSelect(plan)} 
              >
                <div className="bg-[#ff6b6b] text-white text-xs px-2 py-1 rounded absolute top-2 right-2">
                  Last SOLD: {plan.lastSold} ago
                </div>
                <h3 className="text-2xl font-bold mb-2">{plan.duration}</h3>
                <div className="text-4xl font-extrabold mb-1">${plan.price}</div>
                <div className="text-sm mb-4 text-gray-500">{plan.totalLabel || 'per month'}</div>
                <div className="bg-green-100 text-green-800 text-sm px-2 py-1 rounded inline-block mb-2">
                  SAVE {plan.save}
                </div>
                <div className="text-sm mb-4">{plan.guarantee} money-back guarantee</div>
                {plan.originalPrice && (
                  <div className="text-sm text-gray-600">
                    <span className="line-through">${plan.originalPrice}</span>{' '}
                    <span className="font-semibold">${plan.price} billed one time</span>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Add a 24-Hour Trial button */}
          <div className="col-span-full flex justify-center mt-6">
            <Button
              onClick={() => handlePlanSelect(trialPlan)}
              className="bg-[#ff6b6b] text-white py-4 px-8 rounded-lg font-semibold text-lg hover:bg-[#ff5757] transition-colors duration-200"
            >
              Get 24-Hour Trial for $1.99
            </Button>
          </div>
        </section>

        {/* Step 2: Fill Your Details */}
        <section ref={detailsSectionRef}>
          <div className="flex items-center mb-6">
            <span className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white px-3 py-1 rounded-full text-sm mr-3">Step 2</span>
            <h2 className="text-4xl font-extrabold text-[#ff6b6b]">Fill Your Details</h2>
          </div>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          
            <div className="lg:col-span-2 bg-white rounded-lg p-8 shadow-md">
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className="w-full py-4" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2" htmlFor="device">
                  Device Type
                </label>
                <Select
                  id="device"
                  value={deviceType}
                  onValueChange={setDeviceType}
                  required
                >
                  <Select.Item value="amazon">Amazon Fire stick / Fire tv</Select.Item>
                  <Select.Item value="smarttv">Smart TV</Select.Item>
                  <Select.Item value="android">Android Device</Select.Item>
                  <Select.Item value="magbox">Mag Box / Formular Z8</Select.Item>
                  <Select.Item value="apple">Apple Device / Mac OS</Select.Item>
                  <Select.Item value="windows">Windows Device</Select.Item>
                  <Select.Item value="enigma">Enigma / Zgemma</Select.Item>
                  <Select.Item value="m3u">M3u Url</Select.Item>
                  <Select.Item value="other">Other Device</Select.Item>
                </Select>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">Payment Method</label>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                      paymentMethod === 'paypal' ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white' : 'border-gray-300'
                    }`}
                    onClick={() => setPaymentMethod('paypal')}
                  >
                    <img
                      src="https://res.cloudinary.com/benejma/image/upload/v1726676015/paypal_qtp6ny.png"
                      alt="PayPal"
                      className="w-5 h-5"
                    />
                    <span>PayPal</span>
                  </button>
                  <button
                    type="button"
                    className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                      paymentMethod === 'creditcard' ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white' : 'border-gray-300'
                    }`}
                    onClick={() => setPaymentMethod('creditcard')}
                  >
                    <img
                      src="https://res.cloudinary.com/benejma/image/upload/v1726676016/atm-card_pffw03.png"
                      alt="Credit Card"
                      className="w-5 h-5"
                    />
                    <span>Credit Card</span>
                  </button>
                </div>
                <div className="mt-4 flex items-center space-x-2 text-gray-500 text-sm">
                  <ShieldCheck className="text-green-500" />
                  <span>Secure payment with SSL encryption</span>
                </div>
              </div>
              <div className="bg-gray-50 p-6 rounded mb-6">
                <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
                <div className="flex justify-between mb-2">
                  <span>{selectedPlan ? `${selectedPlan.duration} Pass - All Channels & VOD` : 'No plan selected'}</span>
                  <span>${selectedPlan ? selectedPlan.price : '0.00'}</span>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between font-semibold text-lg">
                  <span>Total</span>
                  <span className="text-[#ff6b6b] text-3xl">${selectedPlan ? selectedPlan.price : '0.00'}</span>
                </div>
              </div>
              <Button
                type="submit"
                className="w-full bg-[#ff6b6b] text-white py-4 rounded-lg font-semibold text-lg flex items-center justify-center hover:bg-[#ff5757] transition-colors duration-200"
                disabled={!selectedPlan || !email || !deviceType || !paymentMethod}
              >
                CONTINUE <ChevronRight className="ml-2" />
              </Button>
              <div className="text-center text-sm mt-4 text-gray-600">
                <Check className="inline-block mr-1 text-green-500" size={16} /> You're 100% backed by our 30-day money-back guarantee.
              </div>
            </div>
            <div className="lg:col-span-1 space-y-6">
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Coupon Code</h3>
                <div className="flex">
                  <Input
                    type="text"
                    className="flex-grow"
                    placeholder="Enter coupon code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <Button className="ml-2">Apply</Button>
                </div>
              </div>
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Your Plan Includes:</h3>
                <ul className="text-sm space-y-3">
                  {[
                    '54,000 TV Channels, Movies & Series',
                    '4K / Ultra HD / HD Picture Quality',
                    'Catch Up / EPG For All Channels',
                    'International Live & Sports',
                    '99.9% Uptime Servers',
                    'Video-on-Demand & Series Package',
                    'All Devices Supported',
                    'Adult Channels - Switch ON/OFF',
                  ].map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="text-green-500 mr-2 mt-1 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </form>
        </section>
      </main>
      <button className="fixed bottom-4 right-4 bg-white p-2 rounded-full shadow-md hover:shadow-lg transition-shadow duration-200">
        <Info className="h-6 w-6 text-[#ff6b6b]" />
      </button>
    </div>
  );
};

export default CheckoutPage;