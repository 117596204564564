import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
  const [orders, setOrders] = useState([]); // State for orders
  const [loading, setLoading] = useState(true); // Loading state
  const [searchQuery, setSearchQuery] = useState(''); // Search state
  const [expandedEmails, setExpandedEmails] = useState([]); // Track expanded customers
  const [selectedActions, setSelectedActions] = useState({}); // Track selected actions for each order

  // Fetch orders from the backend API
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`/api/orders`); // Backend API URL
        if (response.data && Array.isArray(response.data)) {
          console.log(response.data);
          setOrders(response.data); // Set orders if data is valid
        }
        setLoading(false); // Stop loading
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false); // Stop loading even if there's an error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch orders. Please check the backend server.',
        });
      }
    };
    fetchOrders();
  }, []);

  // Group orders by email
  const groupedOrders = orders.reduce((acc, order) => {
    if (!acc[order.email]) {
      acc[order.email] = [order]; // Create array for new email
    } else {
      acc[order.email].push(order); // Push orders for the same email
    }
    return acc;
  }, {});

  // Toggle expanded emails to show more orders for a customer
  const toggleExpand = (email) => {
    setExpandedEmails((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]
    );
  };

  // Handle action change for each order
  const handleActionChange = (order_id, action, currentStatus) => {
    switch (action) {
      case 'delete':
        confirmDelete(order_id); // Confirm before deleting
        break;
      case 'togglePaymentStatus':
        togglePaymentStatus(order_id, currentStatus); // Toggle payment status
        break;
      default:
        console.log(`Action selected for Order ID ${order_id}: ${action}`);
    }
  };

  // Toggle payment status between 'Paid' and 'Pending'
  const togglePaymentStatus = async (order_id, currentStatus) => {
    const newStatus = currentStatus === 'Paid' ? 'Pending' : 'Paid';
    try {
      const response = await axios.post(`/api/orders/toggle-payment-status`, {
        order_id,
        payment_status: newStatus,
      });
      if (response.data.success) {
        // Update the state to reflect the new payment status
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === order_id ? { ...order, payment_status: newStatus } : order
          )
        );
        Swal.fire({
          icon: 'success',
          title: `Payment status changed to ${newStatus}`,
          text: `Order ${order_id} has been updated successfully!`,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error updating payment status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update payment status',
      });
    }
  };

  // Confirm order deletion
  const confirmDelete = (order_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete order ${order_id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(order_id); // Proceed with deletion if confirmed
      }
    });
  };

  // Delete order from the backend and update state
  const deleteOrder = async (order_id) => {
    try {
      const response = await axios.delete(`/api/orders/${order_id}`);
      if (response.data.success) {
        // Update state by removing deleted order
        setOrders((prevOrders) => prevOrders.filter((order) => order.order_id !== order_id));
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `Order ${order_id} has been deleted successfully!`,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete order',
      });
    }
  };

  // Filter emails based on the search query
  const filteredEmails = Object.keys(groupedOrders).filter((email) =>
    email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8 mt-16">
      <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Admin Dashboard</h1>
        <p className="text-gray-600">Manage orders and monitor your sales activity.</p>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Search by Email..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Orders Table */}
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        {loading ? (
          <div className="p-4 text-center">Loading orders...</div>
        ) : filteredEmails.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Order ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Plan
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Device Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Method
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Order Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Retry Count
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEmails.map((email) => {
                const latestOrder = groupedOrders[email][0]; // Get the latest order
                const allOrders = groupedOrders[email]; // Get all orders for the email

                return (
                  <React.Fragment key={email}>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {/* Dropdown button */}
                        <button
                          onClick={() => toggleExpand(email)}
                          className="mr-2 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d={expandedEmails.includes(email) ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
                            />
                          </svg>
                        </button>
                        {latestOrder.order_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {latestOrder.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {latestOrder.plan}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {latestOrder.device_type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {latestOrder.payment_method}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(latestOrder.order_time).toLocaleString()}
                      </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
  <span
    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
      latestOrder.payment_status === 'Paid'
        ? 'bg-green-100 text-green-800' // Green for Paid
        : latestOrder.payment_status === 'COMPLETED' || latestOrder.payment_status === 'COMPLETED'
        ? 'bg-purple-100 text-purple-800' // Purple for Completed
        : latestOrder.payment_status === 'Pending'
        ? 'bg-yellow-100 text-yellow-800' // Yellow for Pending
        : 'bg-gray-100 text-gray-800' // Default for any unknown status
    }`}
  >
    {latestOrder.payment_status}
  </span>
</td>


                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {latestOrder.retry_count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <select
                          value={selectedActions[latestOrder.order_id] || ''}
                          onChange={(e) =>
                            handleActionChange(
                              latestOrder.order_id,
                              e.target.value,
                              latestOrder.payment_status
                            )
                          }
                          className="p-2 bg-white border border-gray-300 rounded-md shadow-md hover:border-blue-400 focus:border-blue-500 w-full"
                          style={{ minWidth: '160px' }}
                        >
                          <option value="">Select Action</option>
                          <option value="view">View Order</option>
                          <option value="activate">Activate Order</option>
                          <option value="delete">Delete Order</option>
                          <option value="togglePaymentStatus">
                            {latestOrder.payment_status === 'Paid'
                              ? 'Mark as Pending'
                              : 'Mark as Paid'}
                          </option>
                        </select>
                      </td>
                    </tr>

                    {/* Expanded orders (if dropdown is clicked) */}
                    {expandedEmails.includes(email) &&
  allOrders.slice(1).map((order) => (
    <tr key={order.order_id} className="bg-gray-100 border-l-4 border-blue-500">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {order.order_id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {order.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {order.plan}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {order.device_type}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {order.payment_method}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {new Date(order.order_time).toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            order.payment_status === 'Paid'
              ? 'bg-green-100 text-green-800' // Green for Paid
              : order.payment_status === 'Completed' || order.payment_status === 'COMPLETED'
              ? 'bg-purple-100 text-purple-800' // Purple for Completed
              : order.payment_status === 'Pending'
              ? 'bg-yellow-100 text-yellow-800' // Yellow for Pending
              : 'bg-gray-100 text-gray-800' // Default for unknown status
          }`}
        >
          {order.payment_status}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {order.retry_count}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm">
        <select
          value={selectedActions[order.order_id] || ''}
          onChange={(e) =>
            handleActionChange(order.order_id, e.target.value, order.payment_status)
          }
          className="p-2 bg-white border border-gray-300 rounded-md shadow-md hover:border-blue-400 focus:border-blue-500 w-full"
          style={{ minWidth: '160px' }}
        >
          <option value="">Select Action</option>
          <option value="view">View Order</option>
          <option value="activate">Activate Order</option>
          <option value="delete">Delete Order</option>
          <option value="togglePaymentStatus">
            {order.payment_status === 'Paid' ? 'Mark as Pending' : 'Mark as Paid'}
          </option>
        </select>
      </td>
    </tr>
  ))}

                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="p-4 text-center text-gray-500">No orders found</div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
