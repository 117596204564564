import React, { useState } from 'react';
import { Check, ChevronRight, Info, Globe, Server, Users } from 'lucide-react';
import Button from '../components/ui/button';
import { Alert } from '../components/ui/alert';

const ResellerPage = () => {
  const plans = [
    {
      title: 'Basic Reseller',
      price: 150,
      description: 'For small resellers just starting out.',
      features: [
        '100 IPTV Lines',
        'Access to VOD & Channels',
        'API Access for Integration',
        '24/7 Support',
        'Basic Dashboard',
      ],
    },
    {
      title: 'Pro Reseller',
      price: 400,
      description: 'For medium-sized resellers.',
      features: [
        '500 IPTV Lines',
        'Full Access to VOD & Channels',
        'API Integration & Custom Branding',
        'Priority Support',
        'Advanced Dashboard',
      ],
    },
    {
      title: 'Enterprise Reseller',
      price: 1000,
      description: 'For large resellers with high demand.',
      features: [
        'Unlimited IPTV Lines',
        'Full Access to VOD & Channels',
        'API Access with Customization',
        'Dedicated Account Manager',
        'Enterprise Dashboard & Tools',
      ],
    },
    {
      title: 'Elite Reseller',
      price: 2000,
      description: 'For top-tier resellers with exclusive benefits.',
      features: [
        'Unlimited IPTV Lines with Premium Features',
        'Dedicated API Access',
        'Exclusive Custom Branding Options',
        'Dedicated VIP Support',
        'Advanced Enterprise Tools',
      ],
    },
  ];

  const [selectedPlan, setSelectedPlan] = useState(null);

  // WhatsApp number and message
  const whatsappNumber = '447898002230'; // Updated WhatsApp number
  const whatsappMessage = `Hello, I would like to become a reseller.`;

  // WhatsApp URL for sending a pre-filled message
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <main className="container mx-auto px-6 py-8 pt-24">
        {/* Introduction Section */}
        <section className="mb-12 text-center">
          <h1 className="text-5xl font-extrabold text-[#ff6b6b] mb-6">Become a Reseller</h1>
          <p className="text-lg text-gray-700">
            Partner with us and start offering premium IPTV services to your clients. Choose the
            best plan for your business and enjoy competitive rates, full access to our services,
            and dedicated support for resellers.
          </p>
        </section>

        {/* Reseller Plans Section */}
        <section className="mb-12">
          <div className="flex items-center mb-6">
            <span className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white px-3 py-1 rounded-full text-sm mr-3">Step 1</span>
            <h2 className="text-4xl font-extrabold text-[#ff6b6b]">Choose Your Reseller Plan</h2>
          </div>
          <Alert className="mb-6">
            <span className="font-semibold">Special Discounts</span> - Get in touch for custom pricing!
          </Alert>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`relative rounded-lg p-6 cursor-pointer border-2 transition-all duration-300 ${
                  selectedPlan?.title === plan.title 
                    ? 'bg-[#ffe7e7] border-[#ff6b6b] shadow-lg scale-105' 
                    : 'bg-white border-gray-300 hover:border-[#ff6b6b] hover:shadow-md'
                }`}
                onClick={() => setSelectedPlan(plan)}
              >
                <h3 className="text-2xl font-bold mb-2">{plan.title}</h3>
                <div className="text-4xl font-extrabold mb-1">£{plan.price}</div>
                <div className="text-sm mb-4 text-gray-500">one-time payment</div>
                <p className="text-gray-600 mb-4">{plan.description}</p>
                <ul className="text-sm space-y-3 mb-4 border-t-2 pt-4 border-gray-200">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="text-green-500 mr-2 mt-1 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        <section className="text-center mt-12 mb-12">
          <a
            href={selectedPlan ? whatsappUrl : whatsappUrl} // Links to WhatsApp with pre-filled message
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className="h-14 px-12 text-lg font-semibold rounded-md shadow-lg transition-all duration-300 hover:scale-105 bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white"
            >
              {selectedPlan ? `Get Started with ${selectedPlan.title} Plan` : 'Contact Us for More Info'}
            </Button>
          </a>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-6 lg:px-16">
            <h2 className="text-center text-4xl font-bold text-gray-900 mb-12">Why Choose Us?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-center">
                <Globe className="h-16 w-16 mx-auto text-indigo-600 mb-4" />
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">Global Reach</h3>
                <p className="text-gray-600">
                  Serve clients worldwide with our extensive channel lineup and global access.
                </p>
              </div>
              <div className="text-center">
                <Server className="h-16 w-16 mx-auto text-indigo-600 mb-4" />
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">Reliable Servers</h3>
                <p className="text-gray-600">
                  99.9% uptime with powerful servers to ensure seamless streaming experiences.
                </p>
              </div>
              <div className="text-center">
                <Users className="h-16 w-16 mx-auto text-indigo-600 mb-4" />
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">Dedicated Support</h3>
                <p className="text-gray-600">
                  Get 24/7 priority support for you and your customers through our expert team.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <button className="fixed bottom-4 right-4 bg-white p-2 rounded-full shadow-md hover:shadow-lg transition-shadow duration-200">
        <Info className="h-6 w-6 text-[#ff6b6b]" />
      </button>
    </div>
  );
};

export default ResellerPage;
