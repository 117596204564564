import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../components/ui/input';
import Button from '../components/ui/button';

const deviceOptions = [
  { value: 'Amazon Fire stick / Fire TV', label: 'Amazon Fire stick / Fire TV' },
  { value: 'Smart TV', label: 'Smart TV' },
  { value: 'Android Device', label: 'Android Device' },
  { value: 'Mag Box / Formular Z8', label: 'Mag Box / Formular Z8' },
  { value: 'Apple Device / Mac OS', label: 'Apple Device / Mac OS' },
  { value: 'Windows Device', label: 'Windows Device' },
  { value: 'Enigma / Zgemma', label: 'Enigma / Zgemma' },
  { value: 'M3u Url', label: 'M3u Url' },
  { value: 'Other Device', label: 'Other Device' }
];

const TrialRequest = () => {
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [device, setDevice] = useState(deviceOptions[0].value); // default to the first device
  const [errorMessage, setErrorMessage] = useState('');
  const [trialRequested, setTrialRequested] = useState(false);
  const navigate = useNavigate();

  const validateWhatsAppNumber = (number) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/; // Validates international phone numbers
    return phoneRegex.test(number);
  };

  const handleTrialRequest = (e) => {
    e.preventDefault();

    if (!validateWhatsAppNumber(whatsappNumber)) {
      setErrorMessage('Please enter a valid WhatsApp number with country code (e.g., +123456789).');
      return;
    }

    const existingTrial = false; // Simulate backend check for existing trial.

    if (existingTrial) {
      setErrorMessage('You have already requested a trial. A trial can only be requested once.');
    } else {
      setTrialRequested(true);
      setErrorMessage('');
      console.log('Trial requested for WhatsApp number:', whatsappNumber);
      console.log('Device selected:', device);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 flex items-center justify-center">
      <div className="container mx-auto px-6 py-12">
        <h2 className="text-3xl lg:text-4xl font-extrabold text-center mb-12 text-[rgb(255,107,107)]">
          Request a Free Trial
        </h2>

        {!trialRequested ? (
          <form
            onSubmit={handleTrialRequest}
            className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg"
          >
            {/* WhatsApp Number */}
            <div className="mb-6">
              <label htmlFor="whatsappNumber" className="block text-base lg:text-lg font-medium mb-2">
                WhatsApp Number
              </label>
              <Input
                type="tel"
                id="whatsappNumber"
                name="whatsappNumber"
                placeholder="Enter your WhatsApp number (e.g., +123456789)"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                required
                className="w-full py-4 px-6 text-base lg:text-lg border rounded-lg"
              />
            </div>

            {/* Device Selection */}
            <div className="mb-6">
              <label htmlFor="device" className="block text-base lg:text-lg font-medium mb-2">
                Select Your Device
              </label>
              <select
                id="device"
                name="device"
                value={device}
                onChange={(e) => setDevice(e.target.value)}
                className="w-full py-4 px-4 border border-gray-300 rounded-lg text-base lg:text-lg bg-white"
              >
                {deviceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Error Message */}
            {errorMessage && (
              <div className="text-red-500 text-sm lg:text-base mb-4">
                {errorMessage}
              </div>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white py-4 rounded-lg font-semibold text-lg flex items-center justify-center hover:from-[#ff4d4d] hover:to-[#ff9100] transition-colors duration-200"
            >
              Request Trial
            </Button>
          </form>
        ) : (
          <div className="text-center">
            <h3 className="text-lg lg:text-xl font-semibold text-[rgb(59,130,246)] mt-8">
              Trial Request Sent!
            </h3>
            <p className="mt-4 text-sm lg:text-base">
              Thank you for requesting a trial. Your trial will be delivered to your WhatsApp number between <strong>5 minutes to 8 hours</strong>.
            </p>
            <p className="mt-4 text-sm lg:text-base">
              For fast delivery, contact us on WhatsApp:
            </p>

            <div className="flex justify-center space-x-4 mt-6">
              <a
                href="https://wa.me/447898002230?text=Hello%2C%20I%20am%20interested%20in%20trying%20your%20service.%20Could%20you%20please%20provide%20me%20with%20a%20free%20trial%20for%20the%20Nexatvflix%20IPTV%20service%3F"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-8 py-3 bg-green-500 text-white font-semibold rounded-md shadow-md hover:bg-green-600 transition-colors duration-200 text-base lg:text-lg"
              >
                Contact us on WhatsApp
              </a>

              <button
                className="bg-blue-500 text-white py-3 px-8 rounded-md font-semibold hover:bg-blue-600 transition-colors duration-200 text-base lg:text-lg"
                onClick={() => navigate('/')}
              >
                Go back to Home
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrialRequest;
