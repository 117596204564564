import React from 'react';

const FirestickGuide = () => {
  return (
    <div className="container mx-auto px-6 py-16 mt-20">
      <h2 className="text-3xl font-extrabold mb-8 text-[#ff6b6b]">IPTV Smarters Installation Guide for Amazon Firestick</h2>
      <p className="mb-6">
        To install IPTV Smarters on your Amazon Firestick, follow these steps:
      </p>
      <ol className="list-decimal list-inside space-y-4">
        <li>Go to the Firestick home screen, then "Find" → "Search" → Search for "Downloader" and download it.</li>
        <li>Enable Apps from Unknown Sources in **Settings** → **My Fire TV** → **Developer Options**.</li>
        <li>Open the Downloader app and enter the URL: `https://www.iptvsmarters.com/smarters.apk` and click "Go."</li>
        <li>Once the APK is downloaded, select "Install" to install IPTV Smarters.</li>
        <li>Open IPTV Smarters, agree to terms and log in with the IPTV credentials provided via **email** or **WhatsApp**.</li>
        <li>Start streaming and enjoy IPTV on your Firestick!</li>
      </ol>

      <div className="mt-8 mb-6 border-t-2 pt-6">
        <p>If you need further assistance, feel free to contact us via email at <strong>support@FlixSphere.com</strong> or through WhatsApp for a faster response.</p>
      </div>

      <div className="text-center mt-8">
        <a
          href="https://wa.me/447898002230?text=Hi%20I%20need%20help%20setting%20up%20IPTV%20on%20my%20Firestick."
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white font-semibold py-3 px-8 rounded-md hover:bg-green-600 transition-colors"
        >
          Contact us on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default FirestickGuide;
